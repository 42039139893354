// Angular date formats to standard Intl formats.
//
// See https://code.angularjs.org/1.8.2/docs/api/ng/filter/date for details.
export type DateFormat =
  | "medium"
  | "short"
  | "fullDate"
  | "longDate"
  | "mediumDate"
  | "shortDate"
  | "mediumTime"
  | "shortTime";

const formats: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  medium: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  },
  short: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  fullDate: {weekday: "long", year: "numeric", month: "long", day: "numeric"},
  longDate: {year: "numeric", month: "long", day: "numeric"},
  mediumDate: {year: "numeric", month: "short", day: "numeric"},
  shortDate: {year: "numeric", month: "numeric", day: "numeric"},
  mediumTime: {hour: "numeric", minute: "numeric", second: "numeric"},
  shortTime: {hour: "numeric", minute: "numeric"},
};

const month_names = [
  {short: "Jan", long: "January"},
  {short: "Feb", long: "February"},
  {short: "Mar", long: "March"},
  {short: "Apr", long: "April"},
  {short: "May", long: "May"},
  {short: "Jun", long: "June"},
  {short: "Jul", long: "July"},
  {short: "Aug", long: "August"},
  {short: "Sep", long: "September"},
  {short: "Oct", long: "October"},
  {short: "Nov", long: "November"},
  {short: "Dec", long: "December"},
];

const day_names = [
  {short: "Sun", long: "Sunday"},
  {short: "Mon", long: "Monday"},
  {short: "Tue", long: "Tuesday"},
  {short: "Wed", long: "Wednesday"},
  {short: "Thu", long: "Thursday"},
  {short: "Fri", long: "Friday"},
  {short: "Sat", long: "Saturday"},
];

// See https://code.angularjs.org/1.8.2/docs/api/ng/filter/date for details.
type FallbackFormat =
  | "yyyy"
  | "yy"
  | "y"
  | "MMMM"
  | "MMM"
  | "MM"
  | "M"
  | "LLLL"
  | "dd"
  | "d"
  | "EEEE"
  | "EEE"
  | "HH"
  | "H"
  | "hh"
  | "h"
  | "mm"
  | "m"
  | "ss"
  | "s"
  | "sss"
  | "a"
  | "Z";
type FallbackFormatter = (date: Date) => string;

const fallback_formats: Record<FallbackFormat, FallbackFormatter> = {
  yyyy: (date: Date) => `${date.getFullYear()}`.padStart(4, "0"),
  yy: (date: Date) => `${date.getFullYear() % 100}`.padStart(2, "0"),
  y: (date: Date) => `${date.getFullYear()}`,
  MMMM: (date: Date) => month_names[date.getMonth()].long,
  MMM: (date: Date) => month_names[date.getMonth()].short,
  MM: (date: Date) => `${date.getMonth() + 1}`.padStart(2, "0"),
  M: (date: Date) => `${date.getMonth() + 1}`,
  LLLL: (date: Date) => month_names[date.getMonth()].long,
  dd: (date: Date) => `${date.getDate()}`.padStart(2, "0"),
  d: (date: Date) => `${date.getDate()}`,
  EEEE: (date: Date) => day_names[date.getDay()].long,
  EEE: (date: Date) => day_names[date.getDay()].short,
  HH: (date: Date) => `${date.getHours()}`.padStart(2, "0"),
  H: (date: Date) => `${date.getHours()}`,
  hh: (date: Date) => `${((date.getHours() + 11) % 12) + 1}`.padStart(2, "0"),
  h: (date: Date) => `${((date.getHours() + 11) % 12) + 1}`,
  mm: (date: Date) => `${date.getMinutes()}`.padStart(2, "0"),
  m: (date: Date) => `${date.getMinutes()}`,
  ss: (date: Date) => `${date.getSeconds()}`.padStart(2, "0"),
  s: (date: Date) => `${date.getSeconds()}`,
  sss: (date: Date) => `${Math.floor(date.getMilliseconds())}`.padStart(3, "0"),
  a: (date: Date) => (date.getHours() < 12 ? "AM" : "PM"),
  Z: (date: Date) =>
    date.getTimezoneOffset() < 0
      ? "-" + `${-date.getTimezoneOffset()}`.padStart(4, "0")
      : "+" + `${date.getTimezoneOffset()}`.padStart(4, "0"),
  // ww: (date: Date) => "unsupported",
  // w: (date: Date) => "unsupported",
  // G: (date: Date) => "unsupported",
  // GG: (date: Date) => "unsupported",
  // GGG: (date: Date) => "unsupported",
  // GGGG: (date: Date) => "unsupported",
};
const fallback_formats_entries: [FallbackFormat, FallbackFormatter][] = Object.entries(fallback_formats) as [
  FallbackFormat,
  FallbackFormatter
][];

function fallback_formatters(date: Date): Record<FallbackFormat, () => string> {
  return Object.fromEntries(
    fallback_formats_entries.map(([format, formatter]) => [format, () => formatter(date)])
  ) as Record<FallbackFormat, () => string>;
}

const utc_fallback_formats: Record<FallbackFormat, FallbackFormatter> = {
  yyyy: (date: Date) => `${date.getUTCFullYear()}`.padStart(4, "0"),
  yy: (date: Date) => `${date.getUTCFullYear() % 100}`.padStart(2, "0"),
  y: (date: Date) => `${date.getUTCFullYear()}`,
  MMMM: (date: Date) => month_names[date.getUTCMonth()].long,
  MMM: (date: Date) => month_names[date.getUTCMonth()].short,
  MM: (date: Date) => `${date.getUTCMonth() + 1}`.padStart(2, "0"),
  M: (date: Date) => `${date.getUTCMonth() + 1}`,
  LLLL: (date: Date) => month_names[date.getUTCMonth()].long,
  dd: (date: Date) => `${date.getUTCDate()}`.padStart(2, "0"),
  d: (date: Date) => `${date.getUTCDate()}`,
  EEEE: (date: Date) => day_names[date.getUTCDay()].long,
  EEE: (date: Date) => day_names[date.getUTCDay()].short,
  HH: (date: Date) => `${date.getUTCHours()}`.padStart(2, "0"),
  H: (date: Date) => `${date.getUTCHours()}`,
  hh: (date: Date) => `${((date.getUTCHours() + 11) % 12) + 1}`.padStart(2, "0"),
  h: (date: Date) => `${((date.getUTCHours() + 11) % 12) + 1}`,
  mm: (date: Date) => `${date.getUTCMinutes()}`.padStart(2, "0"),
  m: (date: Date) => `${date.getUTCMinutes()}`,
  ss: (date: Date) => `${date.getUTCSeconds()}`.padStart(2, "0"),
  s: (date: Date) => `${date.getUTCSeconds()}`,
  sss: (date: Date) => `${Math.floor(date.getUTCMilliseconds())}`.padStart(3, "0"),
  a: (date: Date) => (date.getUTCHours() < 12 ? "AM" : "PM"),
  Z: (date: Date) =>
    date.getTimezoneOffset() < 0
      ? "-" + `${-date.getTimezoneOffset()}`.padStart(4, "0")
      : "+" + `${date.getTimezoneOffset()}`.padStart(4, "0"),
  // ww: (date: Date) => "unsupported",
  // w: (date: Date) => "unsupported",
  // G: (date: Date) => "unsupported",
  // GG: (date: Date) => "unsupported",
  // GGG: (date: Date) => "unsupported",
  // GGGG: (date: Date) => "unsupported",
};
const utc_fallback_formats_entries: [FallbackFormat, FallbackFormatter][] = Object.entries(utc_fallback_formats) as [
  FallbackFormat,
  FallbackFormatter
][];

function utc_fallback_formatters(date: Date): Record<FallbackFormat, () => string> {
  return Object.fromEntries(
    utc_fallback_formats_entries.map(([format, formatter]) => [format, () => formatter(date)])
  ) as Record<FallbackFormat, () => string>;
}

function format_date(date: string | number | Date, format: DateFormat, utc?: boolean): string {
  const formatters = utc ? utc_fallback_formatters : fallback_formatters;
  const {yyyy, yy, y, MMMM, MMM, MM, M, dd, d, EEEE, EEE, HH, H, hh, h, mm, m, ss, s, sss, a, Z} = formatters(
    date instanceof Date ? date : new Date(date)
  );
  switch (format) {
    default:
    case "medium":
      return `${MMM()} ${d()}, ${y()} ${h()}:${mm()}:${ss()} ${a()}`;
    case "short":
      return `${M()}/${d()}/${yy()} ${h()}:${mm()} ${a()}`;
    case "fullDate":
      return `${EEEE()}, ${MMMM()} ${d()}, ${y()}`;
    case "longDate":
      return `${MMMM()} ${d()}, ${y()}`;
    case "mediumDate":
      return `${MMM()} ${d()}, ${y()}`;
    case "shortDate":
      return `${M()}/${d()}/${yy()}`;
    case "mediumTime":
      return `${h()}:${mm()}:${ss()} ${a()}`;
    case "shortTime":
      return `${h()}:${mm()} ${a()}`;
  }
}

export let disable_intl_date_format = false; // Used for testing only

export function cg_date(
  date: string | number | Date,
  format?: DateFormat | DateFormat[],
  utc?: boolean,
  language?: string,
  disable_intl?: boolean
) {
  format = format || "mediumDate";
  language = language || "en";

  // We can only use the Intl API if it is implemented for this browser.
  // If not, we fallback to the AngularJS date formatter.
  let use_intl = Intl !== undefined && Intl.DateTimeFormat !== undefined;

  if (typeof disable_intl === "boolean") {
    if (disable_intl) {
      use_intl = false;
    }
  } else if (disable_intl_date_format) {
    use_intl = false;
  }

  if (use_intl) {
    if (typeof format === "string") {
      // We only use the Intl API if the format is a "named" format (e.g.
      // fullDate), since these are the only ones we can readily map.
      use_intl = !!formats[format];
    } else if (Array.isArray(format)) {
      // We extended the format to accept an array of format specificiers.
      // We can only use the Intl API if ALL of the format specifiers are
      // "named" formats (e.g. ['fullDate', 'shortTime']).
      format.map((fmt) => {
        use_intl = use_intl && !!formats[fmt];
      });
    } else {
      use_intl = false;
    }
  }

  let locales: string[] = [];
  if (use_intl) {
    // It's important to include a location with the locale (e.g. en-US,
    // en-AU) as the location can cause significant changes to the output
    // of dates in numeric format.
    //
    // So, here we try to ensure we have a `$language-$LOCATION` type of
    // locale specified. The order of preference (from highest to lowest)
    // is:
    //
    //    1. The selected language if the selected language includes a
    //       LOCATION
    //
    //    2. The selected language if it matches one of the configured
    //       browser languages (e.g. en-US, en-AU, fr-FR, fr-CA, it-IT)
    //
    //    3. The selected non-localised language (e.g. simply: en, fr, it)
    if (/[-_]/.test(language)) {
      // The selected language includes a LOCATION.
      locales.push(language);
    } else {
      // The localised browser languages that match the selected language.
      const browser_languages = window.navigator.languages || [];
      browser_languages.map((browser_language) => {
        if (language === browser_language || new RegExp("^" + language + "[-_]").test(browser_language)) {
          locales.push(browser_language);
        }
      });
    }
    if (locales.length === 0) {
      // The select language did not match any of the localised browser
      // languages, so just use the non-localised language.
      locales.push(language);
    }

    // Filter the requested locales to what is supported.
    try {
      locales = Intl.DateTimeFormat.supportedLocalesOf(locales);
    } catch {
      // `Intl.DateTimeFormat.supportedLocalesOf()` can throw a RangeError if the locale is somehow invalid.
      locales = [];
    }

    if (typeof date === "string") {
      date = new Date(date);
    }

    let intl_format: Intl.DateTimeFormatOptions | undefined = undefined;
    if (typeof format === "string") {
      intl_format = formats[format];
    } else if (Array.isArray(format)) {
      // Combine the formats, into a single format.
      intl_format = {};
      format.map((fmt) => {
        intl_format = {...intl_format, ...formats[fmt]};
      });
    }

    if (utc) {
      intl_format = {...(intl_format ?? {}), timeZone: "UTC"};
    }

    return Intl.DateTimeFormat(locales, intl_format).format(date);
  } else {
    // The Intl API is not available, or the format/s specified are not
    // able to be converted to the Intl API equivalent, so fallback to the
    // AngularJS version.
    if (Array.isArray(format)) {
      return format.map((format) => format_date(date, format, utc)).join(" ");
    } else {
      return format_date(date, format, utc);
    }
  }
}

export function format_iso8601_interval(delta: number): string {
  delta = Math.abs(delta);
  let formatted: string;
  if (delta >= 86400) {
    formatted = Math.floor(delta / 86400).toFixed(0);
    formatted += Number.parseInt(formatted) === 1 ? " day" : " days";
  } else if (delta >= 3600) {
    formatted = Math.floor(delta / 3600).toFixed(0);
    formatted += Number.parseInt(formatted) === 1 ? " hour" : " hours";
  } else if (delta >= 60) {
    formatted = Math.floor(delta / 60).toFixed(0);
    formatted += Number.parseInt(formatted) === 1 ? " minute" : " minutes";
  } else {
    formatted = Math.floor(delta).toFixed(0);
    formatted += Number.parseInt(formatted) === 1 ? " second" : " seconds";
  }
  return formatted;
}

export function format_iso8601(ts: string | number | Date, fmt: "long" | "ago" | "moment" | "interval") {
  const d = new Date(ts);
  switch (fmt) {
    case "long":
      return d.toUTCString();
    case "ago": {
      const now = Date.now();
      let delta = Math.floor((now - d.getTime()) / 1000);
      const sign = delta >= 0 ? "-" : "+";
      delta = Math.abs(delta);
      if (delta >= 86400) {
        return sign + Math.floor(delta / 86400).toFixed(0) + "d" + Math.floor((delta % 86400) / 3600).toFixed(0) + "h";
      } else if (delta >= 3600) {
        return sign + Math.floor(delta / 3600).toFixed(0) + "h" + Math.floor((delta % 3600) / 60).toFixed(0) + "m";
      } else if (delta >= 600) {
        return sign + Math.floor(delta / 60).toFixed(0) + "m";
      } else if (delta >= 60) {
        return sign + Math.floor(delta / 60).toFixed(0) + "m" + Math.floor(delta % 60).toFixed(0) + "s";
      } else {
        return sign + Math.floor(delta).toFixed(0) + "s";
      }
    }
    case "moment": {
      const now = Date.now();
      const delta = Math.floor((now - d.getTime()) / 1000);
      const past = delta >= 0;
      const formatted = format_iso8601_interval(delta);
      return past ? formatted + " ago" : "in " + formatted;
    }
    case "interval": {
      const now = Date.now();
      const delta = Math.floor((now - d.getTime()) / 1000);
      return format_iso8601_interval(delta);
    }
    default:
      return ts;
  }
}
