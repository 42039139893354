interface Cookies {
  [name: string]: string;
}

interface SessionSettings {
  [property: string]: any;
}

let last_cookie: string | undefined = undefined;
let cookies: Cookies = {};
let last_session_settings_cookie: string | undefined = undefined;
let session_settings: SessionSettings | undefined = undefined;

function decoded_cookies(): Cookies {
  if (document.cookie !== last_cookie) {
    cookies = Object.fromEntries(
      document.cookie
        .split(";")
        .map((c) => c.trim())
        .map((c) => {
          const index = c.indexOf("=");
          let name = index < 0 ? c : c.slice(0, index).trim();
          let value = index < 0 ? "" : c.slice(index + 1).trim();
          return [name, value];
        })
    );
  }
  return cookies;
}

export function cg_session<T = any>(property: string): T | void {
  const cookies = decoded_cookies();
  if (cookies["session_settings"] !== last_session_settings_cookie) {
    last_session_settings_cookie = cookies["session_settings"];
    const value = last_session_settings_cookie
      ? JSON.parse(decodeURIComponent(last_session_settings_cookie))
      : undefined;
    session_settings = value && typeof value === "object" ? value : undefined;
  }
  if (session_settings && property in session_settings) {
    return session_settings[property] as T;
  }
}

export function cg_support_session_key(): string | void {
  const cookies = decoded_cookies();
  const support_session_key = cookies["support_session_key"];
  if (support_session_key) {
    let api_key: any = support_session_key;
    if (support_session_key[0] === '"') {
      api_key = JSON.parse(support_session_key);
    }
    if (typeof api_key === "string") {
      return api_key;
    }
  }
}
