import {derived, writable, type Writable, get} from "svelte/store";

export type LocaleTranslations = {[id: string]: string};
export type Translations = {[code: string]: LocaleTranslations};
export type TranslateToFn = (code: string, id: string) => string;
export type TranslateFn = (id: string) => string;
export type LoadLocaleFn = () => Promise<LocaleTranslations>;
export type AvailableLocale = {loader: LoadLocaleFn};
export type AvailableLocales = {[code: string]: AvailableLocale};

const {set: set_code, subscribe: subscribe_code, update: update_code} = writable<string>();

export {default as Translate} from "./Translate.svelte";
export {default as TranslateParam} from "./TranslateParam.svelte";

export const DEFAULT_LANGUAGE = "en";
export const available = writable<AvailableLocales>({});
export const language: Writable<string> = {set: set_language, update: update_code, subscribe: subscribe_code};
export const active_language: Writable<string> = writable();
export const translations = writable<Translations>({});
export const translate_to = derived<typeof translations, TranslateToFn>(
  translations,
  (translations: Translations, set: (value: TranslateToFn) => void) => {
    set((code: string, id: string): string => {
      if (code && code !== "en" && translations[code]) {
        return translations[code][id] || id;
      } else {
        return id;
      }
    });
  }
);
export const translate = derived<[typeof active_language, typeof translate_to], TranslateFn>(
  [active_language, translate_to],
  ([code, translate_to]: [string, TranslateToFn], set: (value: TranslateFn) => void) => {
    set((id: string): string => {
      return translate_to(code, id);
    });
  }
);
export const _ = translate;

export function set_language(language_code: string): void {
  if (language_code) {
    const available_translations = get(available);
    const installed_translations = get(translations);

    if (language_code in installed_translations || language_code in available_translations) {
      set_code(language_code);
    } else {
      language_code = DEFAULT_LANGUAGE;
      set_code(language_code);
    }

    if (language_code in installed_translations) {
      active_language.set(language_code);
    } else if (available_translations[language_code]) {
      available_translations[language_code]
        .loader()
        .then((locale: LocaleTranslations) => {
          translations.update((installed: Translations) => {
            installed[language_code] = locale;
            return installed;
          });
        })
        .then(
          () => {
            if (language_code === current_language_code) {
              // Another language was not requested while waiting for this language to
              // load.
              active_language.set(language_code);
            }
          },
          () => {
            console.log(`Failed to load the requested translations for language: ${language_code}`);
          }
        );
    }
  }
}

export function get_language(): string {
  return current_language_code;
}

function on_set_language(language_code: string): void {
  if (!language_code) {
    return;
  }
  current_language_code = language_code;
}

let current_language_code = "";
language.subscribe(on_set_language);
language.set(DEFAULT_LANGUAGE);
