<script context="module" lang="ts">
  export type Variant = "icon+content" | "icon-only" | "content-only" | "icon-link";
  export type Test = {no_clipboard?: boolean; fail?: boolean};

  let warned_no_clipboard_api = false;

  function have_clipboard_api(no_clipboard?: boolean) {
    if (!navigator.clipboard || no_clipboard) {
      if (!warned_no_clipboard_api) {
        console.log("No clipboard API");
        warned_no_clipboard_api = true;
      }
      return false;
    } else {
      return true;
    }
  }

  function to_variant(variant?: string): Variant {
    switch (variant) {
      case "icon-only":
      case "icon-link":
      case "icon+content":
      case "content-only":
        return variant;
      default:
        return "icon+content";
    }
  }

  function variant_classes(variant: Variant): string {
    return variant === "icon-link" ? "btn btn-link btn-short" : "btn btn-default";
  }
</script>

<script lang="ts">
  import {onDestroy} from "svelte";
  import {_} from "$lib/translate";

  let optional_variant: Variant | undefined = undefined;
  let alt_class: string | undefined = undefined;

  export {optional_variant as variant};
  export let data: string | undefined = undefined;
  export let id: string | undefined = undefined;
  export {alt_class as class};
  export let and_class: string | undefined = undefined;
  export let test: Test | undefined = undefined;

  let cancel_copied_reset: (() => void) | undefined;
  let copying = false;
  let copied = false;
  let failed = false;

  function copy() {
    if (disabled) {
      return;
    }

    const text = data ?? "";

    copying = true;
    copied = false;
    failed = false;

    if (cancel_copied_reset) {
      cancel_copied_reset();
    }

    navigator.clipboard
      .writeText(text)
      .then(
        function () {
          copying = false;
          if (test?.fail) {
            failed = true;
          } else {
            copied = true;
          }
        },
        function () {
          copying = false;
          failed = true;
        }
      )
      .finally(() => {
        // Leave the copied/failed flags enabled for 3 seconds
        return new Promise<void>((resolve, reject) => {
          const id = setTimeout(() => {
            // Clear flags after delay has completed
            copied = failed = false;
            resolve();
          }, 3000);
          cancel_copied_reset = () => {
            // Clear flags after delay has been cancelled
            copied = failed = false;
            cancel_copied_reset = undefined;
            clearTimeout(id);
            reject();
          };
        });
      });
  }

  onDestroy(() => {
    if (cancel_copied_reset) {
      cancel_copied_reset();
    }
  });

  $: hidden = !have_clipboard_api(test?.no_clipboard);
  $: variant = to_variant(optional_variant);
  $: show_icon = variant.includes("icon");
  $: show_content = variant.includes("content");
  $: disabled = !data || copying ? true : undefined;
  $: base_classes = alt_class ? alt_class : variant_classes(variant);
  $: add_classes = and_class ? ` ${and_class}` : "";
  $: classes = base_classes + add_classes;
  $: title = $_("Copy to clipboard");
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- set disabled via spread attributes to avoid attribute error for SPAN elements -->
<span {...{disabled}} {id} class:hidden class={classes} {title} on:click={copy}>
  {#if show_icon}
    {#if !copied && !failed}
      <span class="glyphicon glyphicon-copy" />
    {:else if copied}
      <span class="glyphicon glyphicon-ok" />
    {:else if failed}
      <span class="glyphicon glyphicon-remove" />
    {/if}
  {/if}
  {#if show_content}
    <slot>
      {title}
    </slot>
  {/if}
</span>
