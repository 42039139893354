<script lang="ts">
  export let id: string | undefined = undefined;
</script>

<div class="lightbox" {id}>
  <div class="container">
    <div class="row lightbox-content">
      <slot />
    </div>
  </div>
</div>
