<script context="module" lang="ts">
  import {cg_date} from "./date";
  import type {DateFormat} from "./date";
  import {language} from "./translate";

  export type {DateFormat} from "./date";

  export interface Props {
    date: string | number | Date;
    format?: DateFormat | DateFormat[];
    utc?: boolean;
    language?: string;
    disable_intl?: boolean;
  }
</script>

<script lang="ts">
  export let date: Props["date"];
  export let format: Props["format"] = undefined;
  export let utc: Props["utc"] = undefined;
  let language_override: Props["language"] = undefined;
  export {language_override as language};
  export let disable_intl: Props["disable_intl"] = undefined;

  $: text = cg_date(
    date instanceof Date ? date : new Date(date),
    format,
    utc,
    language_override || $language,
    disable_intl
  );
</script>

{text}
