<script context="module" lang="ts">
  export type AlertLevel = "success" | "info" | "warning" | "danger";

  function alert_level_for(value?: AlertLevel) {
    switch (value) {
      case "success":
      case "info":
      case "warning":
      case "danger":
        return value;
      default:
        return "info";
    }
  }
</script>

<script lang="ts">
  import Lightbox from "./Lightbox.svelte";

  export let id: string | undefined = undefined;
  let alert_level_opt: AlertLevel | undefined = undefined;
  export {alert_level_opt as alert_level};
  export let no_heading: boolean | undefined = undefined;

  $: alert_level = alert_level_for(alert_level_opt);
  $: show_heading = $$slots.heading && !no_heading;
</script>

<Lightbox {id}>
  <div class="col-sm-offset-2 col-sm-8">
    <div class="panel panel-{alert_level}">
      {#if show_heading}
        <div class="panel-heading"><slot name="heading" /></div>
      {/if}
      <div class="panel-body"><slot /></div>
    </div>
  </div>
</Lightbox>
