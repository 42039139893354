<!-- @component Uniform data presentation.

## Parameters
@param {any} value - The value to be rendered.

@param {boolean} nolink - Optionally disable rendering URLs as links.

Default is `false`: render URLs as links.

@param {boolean} typeinfo - Optionally render the type of the value.

Default is `false`: do not render type information.
-->
<script context="module" lang="ts">
  import Json from "./Json.svelte";

  export interface Props {
    value: any;
    parsejson?: boolean;
    noempty?: boolean;
    nolink?: boolean;
    nonull?: boolean;
    noplaceholders?: boolean;
    noundefined?: boolean;
    typeinfo?: boolean;
  }

  const WS_RE = /(^\s)|(\s$)/;

  function preformat(value: string): boolean {
    return value.indexOf("\n") !== -1 || WS_RE.test(value);
  }

  function show_as_link(value: string): boolean {
    const scheme = value.split(":", 1)[0];

    switch (scheme) {
      case "http":
      case "https":
      case "file":
      case "tel":
        return true;
      default:
        return false;
    }
  }

  function update(value: any, parsejson?: Props["parsejson"]) {
    if (typeof value === "string" && parsejson) {
      try {
        value = JSON.parse(value);
      } catch {}
    }
    return {value};
  }
</script>

<script lang="ts">
  let input_value: Props["value"];
  export {input_value as value};
  export let parsejson: Props["parsejson"] = undefined;
  export let noempty: Props["noempty"] = undefined;
  export let nolink: Props["nolink"] = undefined;
  export let nonull: Props["nonull"] = undefined;
  export let noplaceholders: Props["noplaceholders"] = undefined;
  export let noundefined: Props["noundefined"] = undefined;
  export let typeinfo: Props["typeinfo"] = undefined;

  $: ({value} = update(input_value, parsejson));
</script>

{#if value === undefined}
  {#if !noundefined && !noplaceholders}
    <em><samp>undefined</samp></em>
  {/if}
{:else if value === null}
  {#if !nonull && !noplaceholders}
    <em><samp>null</samp></em>
  {/if}
{:else if typeof value === "string"}
  {#if value === ""}
    {#if !noempty && !noplaceholders}
      <em>(empty string)</em>
    {/if}
  {:else if preformat(value)}
    <pre>{value}</pre>
  {:else if !nolink && show_as_link(value)}
    <samp><a href={value} target="_blank">{value}</a></samp>
  {:else}
    <samp>{value}</samp>
  {/if}
{:else if typeof value === "number" || typeof value === "bigint"}
  <em><samp>{value}</samp></em>
{:else if typeof value === "boolean"}
  <em><samp>{value ? "true" : "false"}</samp></em>
{:else if typeof value === "object"}
  <pre><Json {value} /></pre>
{:else}
  <em><code>{value}</code></em>
{/if}
{#if typeinfo}
  <span class="label label-default">{typeof value}</span>
{/if}
